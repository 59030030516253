<template>
  <v-expansion-panels accordion v-model="panel">
    <v-expansion-panel class="px-0 pb-0">
      <v-expansion-panel-header disable-icon-rotate>
        FILTROS
        <template v-slot:actions>
          <v-btn color="primary" fab small>
            <v-icon color="white"> mdi-filter-outline </v-icon>
          </v-btn>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row align="center" justify="center">
          <v-col :class="classTitle" v-if="!!title">
            <v-toolbar-title>
              <v-icon class="mb-1">{{ iconToolbar }}</v-icon>
              <span v-html="title"></span>
            </v-toolbar-title>
          </v-col>
          <v-col
            v-for="(filter, x) in filters"
            :key="x"
            :cols="filter.cols"
            :class="filter.class"
          >
            <v-text-field
              v-if="filter.type == 'input_text'"
              v-model="filter.v_model"
              :label="filter.label"
              v-mask="filter.v_mask"
              append-icon="mdi-magnify"
              @keydown.enter="setData(null)"
              @click:append="setData(null)"
            >
            
            </v-text-field>
            <date-picker
              v-if="filter.type == 'input_date_time'"
              v-model="filter.v_model"
              valueType="YYYY/MM/DD HH:MM"
              time-title-format="YYYY/MM/DD HH:MM"
              :show-second="false"
              @change="setData"
              :range="true"
              type="datetime"
              placeholder="Fecha"
              :confirm="true"
              confirm-text="Seleccionar"
              :show-time-panel="showTimeRangePanel"
            >
              <template v-slot:footer="{ emit }">
                <button
                  class="mx-btn"
                  @click="showTimeRangePanel = !showTimeRangePanel"
                >
                  {{ showTimeRangePanel ? "Fecha" : "Tiempo" }}
                </button>
                <button class="mx-btn" @click="selectToDay(emit)">
                  Hoy
                </button>
              </template>
            </date-picker>
            <v-autocomplete
              v-if="filter.type == 'input_select'"
              v-model="filter.v_model"
              :items="filter.items"
              :item-value="filter.value"
              :item-text="filter.text"
              :label="filter.label"
              :clearable="filter.clearable"
              :multiple="filter.multiple"
              @change="setData"
            />
            <v-menu v-if="filter.type == 'input_date'">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="filter.label"
                  persistent-hint
                  readonly
                  append-icon="mdi-calendar-multiple"
                  v-bind="attrs"
                  v-on="on"
                  :clearable="filter.clearable"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="filter.v_model"
                no-title
                @input="menu = false"
                @change="setData"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-col>
            <v-btn small color="primary" @click="setData"><v-icon left>mdi-magnify</v-icon>buscar</v-btn>
          </v-col> -->
          <v-col :class="classButton" v-if="!!titleButton">
            <v-btn small color="primary" @click="actionButton">
              <v-icon>{{ iconButtom }}</v-icon>
              <span v-html="titleButton"></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";

export default {
  name: "ExpandableFilters",
  components: {
    DatePicker,
  },
  data: function() {
    return {
      panel: "",
      menu: true,
      dates: [],
      showTimePanel: false,
      showTimeRangePanel: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    classTitle: {
      type: String,
      default: "py-0 py-md-2 text-center col-md-2",
    },
    actionButton: {
      type: Function,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: [],
    },
    titleButton: {
      type: String,
      default: "",
    },
    iconButtom: {
      type: String,
      default: "mdi-plus-circle-outline",
    },
    iconToolbar: {
      type: String,
      default: "mdi-plus-circle-outline",
    },
    classButton: {
      type: String,
      default: "",
    },
  },
  computed: {
    dateRangeText: {
      get: function() {
        let obj = this.filters.find((e) => e.type == "input_date");
        let index = this.filters.indexOf(obj);
        return this.filters[index].v_model.join(" ~ ");
      },

      set: function() {
        let obj = this.filters.find((e) => e.type == "input_date");
        let index = this.filters.indexOf(obj);
        this.filters[index].v_model = [];
        this.$emit("datafilters", this.filters);
      },
    },
  },
  methods: {
    setData() {
      let obj = this.filters.find((e) => e.type == "input_date_time");
      if (typeof obj != "undefined") {
        let index = this.filters.indexOf(obj);
        if (this.filters[index].v_model[0] == null)
          this.filters[index].v_model = [];
      }

      this.$emit("datafilters", this.filters);
    },

    selectToDay(emit) {
      const d = new Date();
      let day1 = d.getDate();
      let month1 = d.getMonth() + 1;
      const year1 = d.getFullYear();

      if (month1 < 10) month1 = "0" + month1;

      if (day1 < 10) day1 = "0" + day1;

      const date = [
        new Date(year1 + ", " + month1 + ", " + day1 + " 00:00"),
        new Date(year1 + ", " + month1 + ", " + day1 + " 23:59"),
      ];

      emit(date);
    },
  },
  created() {
    this.panel =
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
        ? ""
        : 0;
  },
  mounted() {
    // document.getElementById('val-date-time-picker').removeAttribute('readonly')
  },
};
</script>
<style>
.mx-input {
  width: 100%;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid #afafaf;
  border-radius: 0 !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mx-datepicker-range {
  width: 100% !important;
}

.mx-input-wrapper input:hover {
  border-color: black !important;
}
.mx-input-wrapper input:active {
  border-color: black !important;
}
</style>
